<template lang="html">

<div class="login">




  <div class="row">
    <div class="card mx-auto">
      <div class="card-header">
        <h4>Login</h4>
      </div>
      <div  id="errorLogin" v-if="errorLogin==1">
        <p>Neispravni podaci.</p>
      </div>
      <div class="card-body">
        <form @submit.prevent="loginUser">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              class="form-control"
              type="text"
              placeholder="Username"
              name="username"
              v-model="username"
              id="username"
              >
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              class="form-control"
              type="password"
              placeholder="Password"
              name="password"
              v-model="password"
              id="password"
              >
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="Login"/>
          </div>




        </form>

      </div>
    </div>

    </div>





    </div>



</template>

<script>
import {mapActions} from 'vuex';

export default {
  data(){
    return{
      username:"",
      password:"",
      errorLogin: 0,

    };
  },
  methods:{
    ...mapActions(['login']),

    loginUser(){
    //  console.log(this.username,this.password)
      // eslint-disable-next-line no-unused-vars
    let user = {
      username: this.username,
      password: this.password
    };
    this.login(user)
    .then(res=>{
      if(res.data.success){
        
        if(res.data.user.type=='admin'){
            this.$router.push('/results');
        }else{
            this.$router.push('/profile');
      }
      }
    }).catch(err =>{
      console.log(err);
      this.errorLogin=1;
    });
    }
  },
  beforeCreate: function() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        document.body.className = 'loginMobile';
      } else {
        document.body.className = 'login';
      }
    }


};
</script>

<style lang="css" scoped>
.login{
  position: absolute;
  top: auto;
  left: 50%;
  right: 1%;

}
@media screen and (max-width: 568px) {
  .login {
    left: 1%;
    right: 1%
  }
}
.row{

  padding-top: 5%;
  text-align: center;



}
.card{
  width: 60%;
  border-radius: 0;

}
.btn{
  width: 60%;
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
.form-group{
padding-top: 10px;
}
.form-control{
  border-radius: 0;
}
.card-header{
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
#errorLogin{
  background-color: red;
  margin-top: 5px;
}
</style>
